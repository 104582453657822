<template>
	<div class="d-flex flex-wrap flex-column align-items-center">
		<div class="kit__utils__avatar kit__utils__avatar--size64 mb-3">
			<img src="resources/images/avatars/user-placeholder.png" :alt="userData.name" />
		</div>
		<div class="text-center">
			<div class="text-dark font-weight-bold font-size-18">{{ userData.name }} {{ userData.surname }}</div>
			<div class="text-uppercase font-size-12 mb-3">{{ roles[userData.role].label }}</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { roles } from '@/constants'

export default {
	name: 'KitGeneral',
	computed: {
		...mapGetters('auth', ['userData']),
	},
	data() {
		return {
			roles,
		}
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
