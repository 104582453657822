<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-4">
					<a-form-item label="Nombre(s)">
						<a-input v-decorator="[
								'name',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Apellidos">
						<a-input v-decorator="[
								'surname',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Correo">
						<a-input v-decorator="[
								'email',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<a-form-item label="Teléfono">
						<a-input v-decorator="[
								'phone',
								{
									rules: [
										{
											required: true,
											message: 'Campo requerido.',
										},
									],
								},
							]" autocomplete="off" v-mask="'##########'" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Contraseña">
						<a-input-password v-decorator="[
								'password',
								{
									rules: [
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: validateToNextPassword,
										},
									],
								},
							]" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Confirmar contraseña">
						<a-input-password @blur="handleConfirmBlur" v-decorator="[
								'confirmPassword',
								{
									rules: [
										{
											min: 6,
											message: 'Ingrese contraseña de al menos 6 caracteres.',
										},
										{
											validator: compareToFirstPassword,
										},
									],
								},
							]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3">
					<div :class="$style.btnPasswordGenerator">
						<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">Generar contraseña</a-button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="offset-md-3 col-md-6 text-center">
					<a-button html-type="submit" icon="save" class="btn btn-success btn-block">Actualizar</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'

export default {
	name: 'generalInformationForm',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('users', ['spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('auth', ['userData']),
	},
	data() {
		return {
			apiURL: process.env.VUE_APP_API_URL,
			form: this.$form.createForm(this),
			inputType: 'password',
			confirmDirty: false,
		}
	},
	mounted() {
		this.fillData()
	},
	methods: {
		fillData() {
			this.form.setFieldsValue({
				name: this.userData.name,
				surname: this.userData.surname,
				email: this.userData.email,
				phone: this.userData.phone,
			})
		},
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.form.setFieldsValue({ password: passwd })
				this.form.setFieldsValue({ confirmPassword: passwd })
			})
		},
		// Personal Information
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
						id: this.userData.id,
					}
					this.$store.dispatch('users/UPDATE', { payload, redirect: false })
				}
			})
		},
	},
	watch: {
		userData: {
			deep: true,
			handler() {
				this.fillData()
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>