<template>
	<div>
		<div class="row">
			<div class="col-xl-4 col-lg-12">
				<div class="card">
					<div class="card-body">
						<kit-general-10v1 />
					</div>
				</div>
			</div>
			<div class="col-xl-8 col-lg-12">
				<div class="card">
					<div class="card-body">
						<userInformation />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1/index'
import userInformation from '@/components/users/partials/userInformation'

export default {
	components: {
		KitGeneral10v1,
		userInformation,
	},
	data() {
		return {
			activeKey: '1',
			form: this.$form.createForm(this),
		}
	},
	methods: {
		callback: function (key) {
			this.activeKey = key
		},
	},
}
</script>
